<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="$ability.can('store', 'attributes') && canAdd"
                variant="primary"
                @click="openEditor(0)"
              >
                <span class="text-nowrap">{{ $t('general.add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('admin.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(avatar)="data">
          <b-img
            rounded
            :src="$options.filters.mediaUrl(data.item, 'avatar', '150x150')"
            height="40"
          />
        </template>

        <template #cell(type)="data">
          {{ $t(`general.paymentGatewayTypes.${data.item.type}`) }}
        </template>

        <template #cell(bank)="data">
          {{ $t(`general.paymentGatewayBanks.${data.item.bank}`) }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <span
              v-if="$ability.can('update', 'attributes')"
              class="btn btn-primary btn-sm mr-1"
              @click="openEditor(data.item.id)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                class="align-middle"
              />
            </span>
            <span
              v-if="$ability.can('destroy', 'attributes')"
              class="btn btn-danger btn-sm"
              @click="destroy(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="align-middle"
              />
            </span>
          </div>
        </template>
      </b-table>
      <value-form
        :opened-value-form.sync="openedValueForm"
        :parent-id="parentId"
        :value-id="valueId"
        :languages="languages"
        @refetch-data="refetchData"
      />
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import GlobalMixin from '@mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useList from './useList'
import storeModule from './storeModule'
import ValueForm from './ValueForm.vue'

export default {
  components: {
    ValueForm,
  },
  mixins: [GlobalMixin],
  props: {
    canAdd: {
      type: [Boolean],
      default: false,
    },
    parentId: {
      type: [Number, null],
      default: null,
    },
    languages: {
      type: [Array],
      default: null,
    },
  },
  data() {
    return {
      openedValueForm: false,
      valueId: 0,
      tableColumns: [
        { key: 'avatar', label: 'Img', sortable: false },
        { key: 'title', label: this.$t('admin.table.fields.title'), sortable: false },
        { key: 'type', label: this.$t('form.type.label'), sortable: false },
        { key: 'bank', label: this.$t('form.bank.label'), sortable: false },
        { key: 'position', label: this.$t('form.position.label'), sortable: false },
        { key: 'actions', label: this.$t('admin.table.fields.actions') },
      ],
    }
  },
  beforeMount() {
    this.idFilter = this.parentId
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/admin/payment_methods/${this.parentId}/gateways/${id}`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    openEditor(valueId) {
      this.valueId = valueId
      this.openedValueForm = true
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'payment_method_gateways'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      idFilter,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      idFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
